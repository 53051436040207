export const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)

export const prettyQuote = (s: string | undefined | null) => {
    if (!s) return ''

    let quote = s

    if (!s.match(/[.?!,]$/)) {
        quote += '.'
    }

    return capitalize(quote)
}

export const timestampToString = (timestamp: number) => {
    let date
    try {
        date = new Date(timestamp * 1000).toISOString()
    } catch (e) {
        try {
            date = new Date(timestamp).toISOString()
        } catch (e) {
            return ''
        }
    }

    if (timestamp < 3600) {
        return date.substring(14, 19)
    }

    const inHours = date.substring(11, 19)

    if (inHours.startsWith('0')) {
        return inHours.substring(1)
    }

    return inHours
}

export const randomString = (length: number) => {
    let rtn = ''

    do {
        rtn += btoa('' + Math.floor(Math.random() * 100000)).substring(0, length)
    } while (rtn.length < length)

    return rtn
}
