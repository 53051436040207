import Cookies from 'universal-cookie'

export default function useApi() {
    async function getJson(endpoint: string) {
        const CSRF = new Cookies().get('XSRF-TOKEN')

        if (!CSRF) {
            await auth()
        }

        return (
            await fetch(endpoint, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-XSRF-TOKEN': CSRF,
                },
                method: 'GET',
                credentials: 'include',
            })
        ).json()
    }

    async function postJson(
        endpoint: string,
        body: Object = {},
        headers: [string, string][] | Record<string, string> | Headers = []
    ): Promise<{
        headers: Headers
        response: Object
    }> {
        return request('POST', endpoint, body, headers)
    }

    async function patchJson(
        endpoint: string,
        body: Object = {},
        headers: [string, string][] | Record<string, string> | Headers = []
    ): Promise<{
        headers: Headers
        response: Object
    }> {
        return request('PATCH', endpoint, body, headers)
    }

    async function request(
        method: string,
        endpoint: string,
        body: Object = {},
        headers: [string, string][] | Record<string, string> | Headers = []
    ): Promise<{
        headers: Headers
        response: Object
    }> {
        const CSRF = new Cookies().get('XSRF-TOKEN')

        if (!CSRF) {
            await auth()
        }

        const res: Response = await fetch(endpoint, {
            headers: {
                ...headers,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-XSRF-TOKEN': CSRF,
            },
            method: method,
            credentials: 'include',
            body: JSON.stringify(body),
        })

        if (!res.ok) {
            return Promise.reject(res)
        }

        const json = await res.json()

        return {
            headers: res.headers,
            response: json,
        }
    }

    async function auth() {
        await fetch('/sanctum/csrf-cookie', {
            method: 'GET',
            credentials: 'same-origin',
        })
    }

    return { getJson, postJson, patchJson }
}
