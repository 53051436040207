<template>
    <div class="relative aspect-video rounded-xl pb-8 text-slate-700">
        <div class="flex h-full w-full items-center justify-center rounded-lg border bg-white px-6 py-4 shadow-sm md:rounded-xl">
            <div class="">
                <div class="relative mx-auto w-9 md:w-12">
                    <PodcastclipfinderIcon animate class="h-9 md:h-12" />
                </div>
                <div class="mt-3 text-center text-sm md:text-base">
                    <div class="font-semibold">{{ selectedTitle }}</div>
                    <div class="text-xs md:text-base">{{ selectedDescription }}</div>
                    <div v-if="selectedPodcasters.length > 1" class="mx-auto mt-3 flex justify-center -space-x-0.5 overflow-hidden">
                        <div
                            v-for="(participant, i) in selectedPodcasters.slice(0, 10)"
                            :key="participant.id"
                            class="size-6 rounded-full bg-white md:size-8"
                            :style="{
                                zIndex: i,
                            }"
                        >
                            <img
                                class="inline-block size-6 rounded-full bg-gray-600 ring-1 ring-gray-200 transition-opacity duration-500 md:size-8"
                                :class="{
                                    'opacity-10': !loaded.includes(participant.id),
                                }"
                                :src="participant.picture_url"
                                :alt="participant.name"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { usePodcasterStore } from '@/Stores/podcaster'
import { storeToRefs } from 'pinia'
import PodcastclipfinderIcon from '@/Icons/PodcastclipfinderIcon.vue'
import { computed, onMounted, ref } from 'vue'
import type { PodcasterResource } from '@/types'

const { title, description } = defineProps<{
    title?: string
    description?: string
}>()

const selectedTitle = computed(() => title || 'Finding relevant clips')
const selectedDescription = computed(() => description || 'Analysing podcasts for relevant clips')

const loaded = ref<string[]>([])
const store = usePodcasterStore()
const { selectedPodcasters } = storeToRefs(store)

onMounted(() => {
    const timePerPodcaster = Math.max(1000, (7 / selectedPodcasters.value.length) * 1000)

    let currentIndex = 0
    const interval = setInterval(() => {
        const podcaster: PodcasterResource | null = selectedPodcasters.value[currentIndex] || null

        if (!podcaster) {
            clearInterval(interval)
            return
        }

        loaded.value.push(podcaster.id)
        currentIndex++
    }, timePerPodcaster)
})
</script>
