<template>
    <ContentLayout>
        <div class="mb-5 flex justify-center">
            <picture class="inline-block size-20 rounded-full bg-gray-400 ring-2 ring-gray-100 md:size-32">
                <source class="size-20 rounded-full md:size-32" :srcset="podcaster.webp_picture_url" type="image/webp" height="160" width="160" />
                <img
                    class="size-20 rounded-full md:size-32"
                    :src="podcaster.picture_url"
                    :alt="podcaster.name"
                    height="160"
                    width="160"
                    loading="lazy"
                />
            </picture>
        </div>
        <PageTitle>
            Start listening to {{ podcaster.podcasts_count }} <br />
            <PageTitleHighlight>{{ podcaster.name }}</PageTitleHighlight>
            podcasts
        </PageTitle>
        <div class="mx-auto mt-6 max-w-3xl py-4">
            <form @submit.prevent="submit" class="relative">
                <SearchInput
                    :rounded-full="true"
                    v-model="question"
                    @submit="submit"
                    :disabled="isWaiting"
                    placeholder="Ask a question..."
                    :error="failed || errors.hasOwnProperty('question') || false"
                />
            </form>
        </div>

        <ConversationStartersList :starters="podcaster.conversation_starters" @selected="submitQuery($event)" />

        <div class="mt-12 flex flex-wrap gap-1">
            <CategoryPill v-for="category in categories" :category="category" />
        </div>

        <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-3 md:grid-cols-2 xl:grid-cols-3" ref="scroller">
            <div v-for="podcast in podcasts" :key="podcast.id">
                <div class="relative mx-auto w-full max-w-4xl overflow-hidden rounded-lg shadow-lg">
                    <div class="h-70">
                        <LiteYouTubeEmbed :id="podcast.external_id" title="Video" ref="iframe" :params="'autoplay=0'" />
                    </div>
                </div>
                <div class="relative flex items-center overflow-hidden">
                    <div class="flex-grow p-2" style="max-width: calc(100% - 1.5rem)">
                        <p class="truncate text-xs font-semibold text-gray-700">
                            {{ new Date(podcast.date)?.toLocaleDateString() }}
                        </p>
                        <div class="truncate text-sm font-semibold text-gray-800 sm:text-base" v-html="podcast.title"></div>
                    </div>
                    <div class="absolute right-2 top-1/2 -translate-y-1/2 transform">
                        <YoutubeIcon class="h-6 w-6 fill-current text-red-600" />
                    </div>
                </div>
            </div>
            <div ref="endOfScroller"></div>
        </div>
        <div class="flex justify-center">
            <div class="relative mx-auto w-9 md:w-12" v-if="isWaiting">
                <PodcastclipfinderIcon animate class="h-9 md:h-12" />
            </div>
        </div>
        <FailedMessage v-if="failed"> We couldn't process more podcasts due to an internal error. Please try again later. </FailedMessage>
    </ContentLayout>
</template>
<script setup lang="ts">
import ContentLayout from '@/Layouts/ContentLayout.vue'
import PageTitle from '@/Pages/Podcaster/PageTitle.vue'
import PageTitleHighlight from '@/Pages/Podcaster/PageTitleHighlight.vue'
import type { PodcasterResource } from '@/types'
import { inject, onMounted, type Ref, ref } from 'vue'
import { definePodcastStore } from '@/Stores/podcast'
import { storeToRefs } from 'pinia'
import { useCategoryStore } from '@/Stores/category'
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/style.css'
import YoutubeIcon from '@/Icons/YoutubeIcon.vue'
import CategoryPill from '@/Components/CategoryPill.vue'
import SearchInput from '@/Components/SearchInput.vue'
import { useConversationStore } from '@/Stores/conversation'
import ConversationStartersList from '@/Pages/Podcaster/ConversationStartersList.vue'
import { router } from '@inertiajs/vue3'
import { OverscrollDirection, type OverscrollOptions, useInfiniteScroll } from '@/Composables/useScrollEvents'
import FailedMessage from '@/Components/FailedMessage.vue'
import PodcastclipfinderIcon from '@/Icons/PodcastclipfinderIcon.vue'

const route = inject('route')
const question: Ref<string | null> = ref(null)
const errors = ref<{ string: string[] }[]>([])
const conversationStore = useConversationStore()
const { sendMessage } = conversationStore
const scroller = ref<HTMLElement>()
const endOfScroller = ref<HTMLElement>()

const { podcaster } = defineProps<{
    podcaster: PodcasterResource
}>()

const submit = () => {
    submitQuery(question.value || '')
}

const submitQuery = async (query: string) => {
    try {
        const conversation = await sendMessage(query, true)
        router.visit(route('clipfinder.conversations.show', [conversation]))
    } catch (e: any) {
        failed.value = true
        errors.value = e.errors || []
    }
}

const store = definePodcastStore(podcaster)
const { podcasts, isWaiting, failed } = storeToRefs(store)
const loadMorePodcasts = store.loadMorePodcasts
const categoryStore = useCategoryStore()
const { categories } = storeToRefs(categoryStore)

onMounted(() => {
    categoryStore.fetchPodcasterCategories(podcaster.id)
    useInfiniteScroll(scroller.value, endOfScroller.value, loadMorePodcasts, {
        direction: OverscrollDirection.bottom,
        rootMargin: '100px',
    } as OverscrollOptions)
})
</script>
