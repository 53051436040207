<template>
    <SelectBox v-model="playbackRate" :options="speeds">
        <ForwardIcon class="mr-1.5 h-4" />
        <span class="block truncate">{{ playbackRate }}x</span>
    </SelectBox>
</template>

<script setup lang="ts">
import { ForwardIcon } from '@heroicons/vue/24/outline'
import SelectBox from '@/Components/SelectBox.vue'
import { usePlayerStore } from '@/Stores/player'
import { storeToRefs } from 'pinia'

const playerStore = usePlayerStore()
const { playbackRate } = storeToRefs(playerStore)

const speeds: { value: number; name: string }[] = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map((speed: number) => ({
    value: speed,
    name: speed + 'x',
}))
</script>
