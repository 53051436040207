<template>
    <ChatLayout>
        <ClipConversation />
    </ChatLayout>
</template>

<script setup lang="ts">
import { defineUserStore } from '@/Stores/user'
import ChatLayout from '@/Layouts/ChatLayout.vue'
import { definePodcasterStore } from '@/Stores/podcaster'
import ClipConversation from '@/Components/ClipConversation.vue'
import type { PodcasterResource } from '@/types'
import { defineConversationStore } from '@/Stores/conversation'
import { useYoutubeScripts } from '@/Composables/useYoutubeScripts'

const { podcaster } = defineProps<{
    podcaster?: PodcasterResource | null
}>()

useYoutubeScripts()

defineUserStore()
defineConversationStore()
definePodcasterStore(podcaster ? [podcaster.id] : undefined).then((store) => {
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.get('openSidebar') === '1') {
        store.toggle()
    }
})
</script>
