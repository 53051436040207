import useEventsBus from '@/Composables/useEventBus'
import type { ClipResource } from '@/types'
import type { ComponentPublicInstance, Ref } from 'vue'
import { nextTick, ref, watch } from 'vue'
import type LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import { usePlayerStore } from '@/Stores/player'
import { storeToRefs } from 'pinia'
import { useYoutubeScripts } from '@/Composables/useYoutubeScripts'

const { on, emit } = useEventsBus()

export default function useYoutube(clip: ClipResource) {
    const playerStore = usePlayerStore()
    const { playbackRate } = storeToRefs(playerStore)

    const iframe: Ref<ComponentPublicInstance<typeof LiteYouTubeEmbed> | null> = ref(null)
    let player: YT.Player | null

    on('playing', ({ id }): void => {
        if (clip.id !== id) pause()
    })

    function onIframeAdded(attempts: number = 0) {
        return nextTick((): void => {
            if (!iframe.value) {
                return
            }

            if (attempts > 3) {
                useYoutubeScripts()
            }

            if (attempts > 5) {
                throw 'YT scripts were not loaded correctly'
            }

            if (typeof YT === 'undefined' || !YT) {
                console.debug('Waiting for YT...')

                attempts++
                setTimeout(() => onIframeAdded(attempts), 1500)
                return
            }

            window.YT?.ready(function () {
                if (!iframe.value) {
                    return
                }

                player = new YT.Player(iframe.value.getPlayerInstance(), {
                    events: {
                        onStateChange: (event: YT.OnStateChangeEvent) => {
                            event.target.setPlaybackRate(parseFloat(playbackRate.value))

                            if (event.data == YT.PlayerState.PLAYING) {
                                emit('playing', {
                                    id: clip.id,
                                    video: clip.podcast.id,
                                })
                            }
                        },
                    },
                })

                watch(playbackRate, () => {
                    player?.setPlaybackRate(parseFloat(playbackRate.value))
                })
            })
        })
    }

    function play(timestamp: number): void {
        emit('playing', {
            id: clip.id,
            video: clip.podcast.id,
            timestamp: timestamp,
        })

        if (!player) {
            iframe.value?.addIframe()
            return
        }

        player.seekTo(timestamp, true)
        player.playVideo()
    }

    function pause(): void {
        player?.pauseVideo()
    }

    function stop(): void {
        player?.stopVideo()
    }

    return {
        iframe,
        onIframeAdded,
        play,
        stop,
    }
}
