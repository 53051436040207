<template>
    <div ref="clipContainer">
        <FailedMessage v-if="selectedClips.length === 0 && message.failed" with-retries @retrying="retry" :retrying="retrying">
            Something went wrong. You can retry by clicking the "Retry" button. If the error keeps occurring, please contact support.
        </FailedMessage>

        <!-- TODO: make sure this isn't shown before the agent is fully finished -->
        <div v-if="selectedClips.length === 0 && message.suggestions.length > 0">
            <ChatBubble align="left" class="rounded-lg border bg-white p-3 font-normal text-slate-600 shadow">
                <div class="flex items-start">
                    <div class="mr-3 flex h-7 w-7 shrink-0 items-center justify-center rounded-full border border-orange-300 bg-orange-100 p-1">
                        <ExclamationTriangleIcon class="h-4 text-orange-600" />
                    </div>
                    <div>
                        <div class="font-semibold text-gray-700">No clips</div>
                        <div class="text-sm text-gray-500">
                            No relevant clips could be found for this query. Do you want to try one of the following topics?
                        </div>
                    </div>
                </div>
            </ChatBubble>
        </div>

        <div class="sm:max-w-xl lg:w-[36rem]">
            <PodcastClip v-for="clip in message.clips || []" :clip="clip" :key="'clip-' + clip.id" :question="message.original_question" />
            <ClipLoader
                key="clip-loader"
                v-if="
                    !shared &&
                    ((selectedClips.length === 0 && message.suggestions.length === 0) || (isLastMessage && scrollState === ScrollState.Waiting))
                "
            />
        </div>

        <div v-if="!shared && message.suggestions.length > 0">
            <div :class="{ 'mt-8': !message.failed }">
                <span v-if="!message.failed && message.suggestions.length > 0" class="text-sm font-medium text-gray-700">
                    <span v-if="message.clips.length > 0">No more clips were found.</span>
                    <span v-else>No clips were found.</span>
                </span>
                <span class="text-sm font-medium text-gray-700 lg:text-base" v-else-if="!message.failed">
                    <span v-if="message.clips.length > 0">No more clips were found.</span>
                    <span v-else>No clips were found.</span>
                </span>
            </div>

            <ClipSuggestions :message="message" @selected="selectSuggestion" />
        </div>

        <div
            class="sticky inset-x-0 bottom-4 z-50 flex items-center justify-center"
            v-if="
                !shared &&
                isLastMessage &&
                selectedClips.length > 0 &&
                message.suggestions.length === 0 &&
                scrollState !== ScrollState.Ended &&
                scrollState !== ScrollState.Failed
            "
        >
            <div class="borders flex items-center rounded-full bg-white/60 px-3 py-0.5 shadow backdrop-blur">
                <VerticalScrollerIcon class="mr-1 h-7 fill-current text-primary-600" />
                <span class="text-sm font-semibold leading-5 text-black/80">Scroll to continue...</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import ChatBubble from '@/Components/ChatBubble.vue'
import ClipLoader from '@/Components/ClipLoader.vue'
import FailedMessage from '@/Components/FailedMessage.vue'
import PodcastClip from '@/Components/PodcastClip.vue'
import VerticalScrollerIcon from '@/Icons/VerticalScrollerIcon.vue'
import { ScrollState, useConversationStore } from '@/Stores/conversation'
import type { ClipFinderMessageResource, ClipSuggestionResource } from '@/types'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import ClipSuggestions from '@/Components/ClipSuggestions.vue'

const emits = defineEmits(['topic-selected'])

const props = defineProps<{
    message: ClipFinderMessageResource
    isLastMessage: Boolean
}>()

const conversationStore = useConversationStore()
const { continueBySuggestedTopic, retryMessage } = conversationStore
const { scrollState, lastMessage, shared } = storeToRefs(conversationStore)

const retrying = ref<Boolean>(false)
const clipContainer = ref<HTMLElement>()

const selectSuggestion = async (suggestion: ClipSuggestionResource) => {
    if (shared.value) {
        return
    }

    await continueBySuggestedTopic(suggestion)

    emits('topic-selected', suggestion.topic)
}

const retry = async () => {
    if (shared.value) {
        return
    }

    retrying.value = true
    await retryMessage(props.message.id, () => (retrying.value = false))
}

const selectedClips = computed(() => props.message.clips.filter((clip) => clip.selected))
</script>
