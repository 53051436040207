<template>
    <ContentLayout>
        <PageTitle>
            Select your
            <PageTitleHighlight>favorite people</PageTitleHighlight>
            <br />
            and start listening
        </PageTitle>
        <div class="mt-6 lg:mt-16" ref="scroller">
            <div class="grid grid-cols-2 gap-x-2 gap-y-6 lg:grid-cols-4 lg:gap-y-12 xl:grid-cols-5">
                <PodcasterCard v-for="podcaster in podcasters" :key="podcaster.id" :podcaster="podcaster" />
            </div>
            <div ref="endOfScroller"></div>
        </div>
        <div class="relative mx-auto w-9 md:w-12" v-if="isWaiting">
            <PodcastclipfinderIcon animate class="h-9 md:h-12" />
        </div>
        <FailedMessage v-if="failed"> We couldn't process more podcasts due to an internal error. Please try again later. </FailedMessage>
    </ContentLayout>
</template>
<script setup lang="ts">
import { usePodcasterStore } from '@/Stores/podcaster'
import PodcasterCard from '@/Components/PodcasterCard.vue'
import { storeToRefs } from 'pinia'
import ContentLayout from '@/Layouts/ContentLayout.vue'
import PageTitle from '@/Pages/Podcaster/PageTitle.vue'
import PageTitleHighlight from '@/Pages/Podcaster/PageTitleHighlight.vue'
import FailedMessage from '@/Components/FailedMessage.vue'
import PodcastclipfinderIcon from '@/Icons/PodcastclipfinderIcon.vue'
import { onMounted, ref } from 'vue'
import { OverscrollDirection, type OverscrollOptions, useInfiniteScroll } from '@/Composables/useScrollEvents'

const store = usePodcasterStore()
const { podcasters, isWaiting, failed } = storeToRefs(store)

const loadMorePodcasters = store.loadMorePodcasters
const scroller = ref<HTMLElement>()
const endOfScroller = ref<HTMLElement>()

onMounted(() => {
    useInfiniteScroll(scroller.value, endOfScroller.value, loadMorePodcasters, {
        direction: OverscrollDirection.bottom,
        rootMargin: '100px',
    } as OverscrollOptions)
})
</script>
