export enum OverscrollDirection {
    top = 'top',
    bottom = 'bottom',
}

export declare type OverscrollOptions = {
    direction: OverscrollDirection
    rootMargin: string
}

export const useInfiniteScroll = (
    el: HTMLElement | undefined,
    ender: HTMLElement | undefined,
    callback: Function,
    options: OverscrollOptions = { direction: OverscrollDirection.bottom, rootMargin: '10px' }
) => {
    if (!el || !ender) {
        return
    }

    onOverscroll(el, callback, options)

    const observer = new IntersectionObserver(
        (entries) => {
            let entry = entries[0]

            if (entry.isIntersecting) {
                callback()
            }
        },
        { root: el, rootMargin: options.rootMargin }
    )

    if (ender) {
        observer.observe(ender)
    }
}

export const onOverscroll = (
    el: HTMLElement,
    callback: Function,
    options: OverscrollOptions = { direction: OverscrollDirection.bottom, rootMargin: '10px' }
) => {
    el.addEventListener('scroll', () => {
        if (!hasBeenScrolledToEnd(el)) {
            return
        }

        callback()
    })

    // Check for desktop mousewheel overscroll
    onWheelEnd(el, (e: WheelEvent) => {
        if (!hasBeenScrolledToEnd(el)) {
            return
        }

        if ((options.direction === OverscrollDirection.bottom && e.deltaY > 0) || (options.direction === OverscrollDirection.top && e.deltaY < 0)) {
            callback()
        }
    })

    // Check for mobile overscroll
    el.addEventListener('touchend', function (e: TouchEvent) {
        if (hasBeenScrolledToEnd(el)) {
            callback()
        }
    })
}

export const onWheelEnd = (el: HTMLElement, callback: Function) => {
    el.addEventListener('wheel', wheel, { passive: true })

    let marker: boolean = true,
        delta: number,
        direction: string = 'down',
        interval: number = 30,
        counter1: number = 0,
        counter2: number

    function wheel(e: WheelEvent): boolean {
        counter1 += 1
        delta = e.deltaY || e.detail
        if (Math.abs(delta) < 50) {
            return false
        }

        direction = delta > 0 ? 'up' : 'down'

        if (marker) {
            wheelStart(e)
        }

        return false
    }

    function wheelStart(e: WheelEvent): void {
        marker = false
        wheelAct(e)
    }

    function wheelAct(e: WheelEvent): void {
        counter2 = counter1
        setTimeout(function () {
            if (counter2 == counter1 || Math.abs(delta) < 50) {
                wheelEnd(e)
            } else {
                wheelAct(e)
            }
        }, interval)
    }

    function wheelEnd(e: WheelEvent): void {
        marker = true
        counter1 = 0
        counter2 = 0

        callback(e)
    }
}

export const hasBeenScrolledToEnd = (el: HTMLElement) => {
    const scrollableHeight = Math.round(el.scrollHeight - el.clientHeight)

    return Math.round(el.scrollTop) >= scrollableHeight
}
