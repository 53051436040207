<template>
    <Layout>
        <Head title="Clipfinder - Go straight to the Youtube Conversation" />

        <div class="relative isolate min-h-[100svh] overflow-hidden bg-slate-50 pb-6 pt-12 lg:flex lg:h-[100svh] lg:flex-col lg:pt-0">
            <div
                class="absolute left-0 top-[10rem] -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-[40%] sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-40"
                aria-hidden="true"
            >
                <div
                    class="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-primary-500/50 to-primary"
                    style="
                        clip-path: polygon(
                            100% 38.5%,
                            82.6% 100%,
                            60.2% 37.7%,
                            52.4% 32.1%,
                            47.5% 41.8%,
                            45.2% 65.6%,
                            27.5% 23.4%,
                            0.1% 35.3%,
                            17.9% 0%,
                            27.7% 23.4%,
                            76.2% 2.5%,
                            74.2% 56%,
                            100% 38.5%
                        );
                    "
                ></div>
            </div>

            <div class="absolute left-0 top-0 mt-6 flex w-full justify-center md:ml-4 md:justify-start">
                <a class="flex cursor-pointer items-center rounded-lg px-2 text-center font-medium" href="/">
                    <PodcastclipfinderIcon class="w-6 shrink-0 fill-current text-gray-900 md:h-10" />
                    <span class="ml-2 text-xl font-black tracking-wide text-gray-900 md:ml-2">Clipfinder</span>
                </a>
            </div>

            <div class="absolute right-4 top-0 mt-4 flex w-full justify-end md:ml-4 md:mt-6 md:justify-end">
                <GoogleLoginButton v-if="!loggedIn" :hideTextOnMobile="true" />
                <Link
                    v-else
                    :href="route('clipfinder.conversations.create')"
                    class="flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-gray-600"
                >
                    <img v-if="user?.avatar_url" :src="user.avatar_url" alt="User Avatar" class="h-full w-full object-cover" />
                    <span v-else class="text-sm font-semibold text-white">{{ user.initials }}</span>
                </Link>
            </div>

            <div class="h-full justify-center lg:flex lg:flex-col">
                <div class="mx-auto mt-8 w-full max-w-4xl px-4 text-center sm:px-6 lg:-mt-20 lg:px-8">
                    <div class="mb-8 flex items-center justify-center lg:mb-16">
                        <div v-if="podcasters.length > 0" class="flex -space-x-14 md:-space-x-24">
                            <Link
                                :style="{ zIndex: 10 - i }"
                                class="inline-block size-24 overflow-hidden rounded-full shadow-[39px_36px_33px_-31px_rgb(0_0_0_/_0.25)] ring-2 ring-white hover:cursor-pointer hover:shadow-[39px_36px_33px_-31px_rgb(0_0_0_/_0.6)] md:size-40"
                                v-for="(participant, i) in podcasters"
                                :key="participant.id"
                                :href="route('clipfinder.podcasters.show', [participant.slug])"
                            >
                                <picture class="h-full w-full">
                                    <source class="h-full w-full" :srcset="participant.webp_picture_url" type="image/webp" height="160" width="160" />
                                    <img
                                        class="h-full w-full"
                                        :src="participant.picture_url"
                                        :alt="participant.name"
                                        height="160"
                                        width="160"
                                        loading="lazy"
                                    />
                                </picture>
                            </Link>
                            <!-- TODO: CLICK TO INDEX PAGE -->
                            <Link
                                :href="route('clipfinder.podcasters.index')"
                                v-if="invisiblePodcastersCount"
                                class="z-0 inline-flex size-24 items-center justify-center rounded-full bg-primary shadow-[39px_36px_33px_-31px_rgb(0_0_0_/_0.25)] ring-2 ring-white md:size-40"
                            >
                                <span class="pl-14 text-base font-bold text-white md:pl-20 md:text-3xl">+{{ invisiblePodcastersCount }}</span>
                            </Link>
                        </div>
                    </div>

                    <h1 class="text-lg font-bold text-slate-800 md:text-3xl xl:text-4xl">
                        Start listening to

                        <span
                            class="ml-0.5 inline-block rounded-lg border-0 bg-primary px-2 py-0.5 text-lg text-white focus:ring-2 focus:ring-primary-400 focus:ring-offset-1 md:text-3xl lg:inline xl:text-4xl"
                        >
                            podcasts
                        </span>

                        <br />
                        where your interest begins.
                    </h1>
                    <p class="mt-4 text-base text-slate-600 md:text-lg">
                        Our AI binged {{ hours.toLocaleString('en-US') }} hours of {{ podcasts.toLocaleString('en-US') }} podcasts. Search everyone,
                        or
                        <Link
                            :href="route('clipfinder.conversations.create', { openSidebar: 1 })"
                            :only="['conversations']"
                            title="Select one or more people"
                        >
                            <u>choose your favorite people</u>
                        </Link>
                    </p>
                </div>
                <div class="mx-auto w-full max-w-2xl px-4 sm:px-6 lg:px-8">
                    <div class="mt-6 flex flex-wrap gap-2 leading-6 text-black md:items-center md:justify-center md:gap-1">
                        <CategoryPill v-for="category in categories" :category="category" />
                    </div>
                </div>

                <div class="mx-auto mt-10 w-full max-w-2xl px-4 sm:px-6 lg:px-8">
                    <form @submit.prevent="submit" class="relative">
                        <SearchInput
                            :rounded-full="true"
                            v-model="question"
                            @submit="submit"
                            :disabled="isWaiting"
                            placeholder="Ask a question..."
                            :error="failed || errors.hasOwnProperty('question') || false"
                        />
                    </form>

                    <ConversationStartersList class="mt-4" :starters="starters" @selected="submitQuery($event)" />
                </div>
                <!-- End Search -->
            </div>

            <footer class="mx-auto mt-auto max-w-4xl px-4 pt-8 text-center sm:px-6 lg:px-8 2xl:pt-0">
                <p class="text-xs text-slate-600">
                    © {{ year }}. A product of
                    <a
                        class="font-semibold text-slate-900 decoration-2 hover:underline dark:text-slate-300"
                        href="https://spartner.software/"
                        target="_blank"
                        title="Spartner"
                    >
                        Spartner</a
                    >.
                    <Link
                        class="text-slate-900 decoration-2 hover:underline dark:text-slate-300"
                        :href="route('clipfinder.privacy')"
                        target="_blank"
                        title="Privacy Policy"
                    >
                        Privacy Policy
                    </Link>
                </p>
                <!--                    <p class="text-xs text-slate-600">-->
                <!--                        <Link-->
                <!--                            class="font-semibold text-slate-900 decoration-2 hover:underline dark:text-slate-300"-->
                <!--                            :href="route('clipfinder.terms')"-->
                <!--                            target="_blank"-->
                <!--                            title="Terms"-->
                <!--                        >-->
                <!--                            Terms-->
                <!--                        </Link>-->
                <!--                    </p>-->
            </footer>
        </div>
        <!-- End Content -->
    </Layout>
</template>

<script setup lang="ts">
import Layout from '@/Layouts/Layout.vue'
import { Link } from '@inertiajs/vue3'

import type { CategoryResource, ConversationStarterResource, PodcasterResource } from '@/types'
import { Head, router } from '@inertiajs/vue3'

import { inject, type Ref } from 'vue'
import { computed, ref } from 'vue'
import PodcastclipfinderIcon from '@/Icons/PodcastclipfinderIcon.vue'
import { ScrollState, useConversationStore } from '@/Stores/conversation'
import { definePodcasterStore } from '@/Stores/podcaster'
import { defineUserStore, useUserStore } from '@/Stores/user'
import SearchInput from '@/Components/SearchInput.vue'
import { storeToRefs } from 'pinia'
import GoogleLoginButton from '@/Components/GoogleLoginButton.vue'
import ConversationStartersList from '@/Pages/Podcaster/ConversationStartersList.vue'
import { defineCategoryStore, useCategoryStore } from '@/Stores/category'
import CategoryPill from '@/Components/CategoryPill.vue'

const route = inject('route')
const props = defineProps<{
    podcasts: number
    hours: number
    podcasters: PodcasterResource[]
    totalPodcastersCount: number
    starters: ConversationStarterResource[]
}>()

defineUserStore()
definePodcasterStore()
defineCategoryStore()

const conversationStore = useConversationStore()
const { sendMessage } = conversationStore
const { scrollState } = storeToRefs(conversationStore)
const userStore = useUserStore()
const { user, loggedIn } = storeToRefs(userStore)
const categoryStore = useCategoryStore()
const { categories } = storeToRefs(categoryStore)

const question: Ref<string | null> = ref(null)
const errors = ref<{ string: string[] }[]>([])
const failed = ref<Boolean>(false)

const submit = () => {
    submitQuery(question.value || '')
}

const submitQuery = async (query: string) => {
    try {
        const conversation = await sendMessage(query, true)
        router.visit(route('clipfinder.conversations.show', [conversation]))
    } catch (e: any) {
        failed.value = true
        errors.value = e.errors || []
    }
}

const isWaiting = computed(() => scrollState.value === ScrollState.Requesting)

const invisiblePodcastersCount = computed(() => Math.max(0, props.totalPodcastersCount - props.podcasters.length))

const year = computed((): number => {
    return new Date().getFullYear()
})
</script>
