<template>
    <li ref="$el" class="relative">
        <div class="group relative active:opacity-90">
            <a
                @click.prevent="openConversation"
                class="flex items-center gap-2 rounded-lg p-2 text-white hover:cursor-pointer hover:bg-gray-700"
                :href="url"
                :class="{ 'bg-gray-700': active }"
            >
                <div class="flex shrink-0 -space-x-3 overflow-hidden">
                    <picture v-for="participant in conversationParticipants" :key="participant.id" class="inline-block size-6 shrink-0 rounded-full">
                        <source class="size-6 rounded-full" :srcset="participant.webp_picture_url" type="image/webp" height="24" width="24" />
                        <img class="size-6 rounded-full" :src="participant.picture_url" :alt="participant.name" height="24" width="24" />
                    </picture>

                    <div v-if="invisiblePodcastersCount > 0" class="z-0 inline-flex size-6 items-center justify-center rounded-full bg-slate-500">
                        <span class="text-[0.5rem] font-bold text-white">+{{ invisiblePodcastersCount }}</span>
                    </div>
                </div>

                <div class="relative grow overflow-hidden whitespace-nowrap" :class="{ 'blur-sm': !loggedIn }">
                    {{ conversation.excerpt }}
                    <div
                        v-if="!active"
                        class="absolute bottom-0 right-0 top-0 w-8 bg-gradient-to-l from-gray-800 to-transparent group-hover:hidden"
                    ></div>
                </div>
            </a>

            <!-- TODO button to share/delete etc -->
            <!--            <button-->
            <!--                v-if="active"-->
            <!--                class="absolute bottom-0 right-0 top-0 flex w-9 items-center justify-center rounded-lg text-white transition"-->
            <!--                type="button"-->
            <!--            >-->
            <!--                <div class="pointer-events-none absolute bottom-0 right-0 top-0 w-14 rounded-lg bg-gradient-to-l from-gray-700 from-60% to-transparent"></div>-->
            <!--                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md relative">-->
            <!--                    <path-->
            <!--                        fill-rule="evenodd"-->
            <!--                        clip-rule="evenodd"-->
            <!--                        d="M3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12Z"-->
            <!--                        fill="currentColor"-->
            <!--                    ></path>-->
            <!--                </svg>-->
            <!--            </button>-->
        </div>
    </li>
</template>

<script setup lang="ts">
import type { ClipFinderConversationResource, PodcasterResource } from '@/types'
import { router } from '@inertiajs/vue3'

import { computed, type ComputedRef, inject, nextTick, onMounted, ref } from 'vue'
import type { Ref } from 'vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { useMenuStore } from '@/Stores/menu'
import { useUserStore } from '@/Stores/user'
import { storeToRefs } from 'pinia'
import { usePodcasterStore } from '@/Stores/podcaster'

const { close } = useMenuStore()
const breakpoints = useBreakpoints(breakpointsTailwind)

const props = defineProps<{
    conversation: ClipFinderConversationResource
    active: Boolean
}>()

const route = inject('route')
const podcasterStore = usePodcasterStore()
const { podcasters } = storeToRefs(podcasterStore)
const userStore = useUserStore()
const { loggedIn } = storeToRefs(userStore)

const $el: Ref<HTMLElement | undefined> = ref<HTMLElement>()

onMounted(() => {
    if (props.active) {
        nextTick(() => $el.value?.scrollIntoView({ block: 'end', inline: 'end' }))
    }
})

const conversationParticipants: ComputedRef<PodcasterResource[]> = computed(() => {
    return podcasters.value.filter((p) => props.conversation.participants.slice(0, 3).includes(p.id))
})

const invisiblePodcastersCount = computed(() => Math.max(0, props.conversation.participants.length - 3))

const url: ComputedRef<string> = computed((): string => route('clipfinder.conversations.show', [props.conversation.id]))

const openConversation = () => {
    router.visit(url.value, {
        only: ['conversation', 'messages', 'shared'],
        preserveScroll: true,
    })

    if (breakpoints.greater('md').value) {
        return
    }

    // Close the menu on mobile
    nextTick(() => close())
}
</script>
