import { nextTick } from 'vue'

type ScrollToBottomBehaviour = {
    smooth?: Boolean
}

const scrollToBottom = (el: HTMLElement | undefined, { smooth }: ScrollToBottomBehaviour = { smooth: false }) => {
    if (!el) {
        return
    }

    if (el.scrollHeight <= el.clientHeight) {
        return
    }

    nextTick(() => {
        setTimeout(() => {
            el.scrollTo({ top: el.scrollHeight, behavior: smooth ? 'smooth' : 'instant' })
        }, 200)
    })
}

export default scrollToBottom
