import type { WatchCallback } from 'vue'
import { ref, watch } from 'vue'

const bus = ref(new Map())

export default function useEventsBus() {
    function emit(event: string, args: {}) {
        bus.value.set(event, args)
    }

    function on(event: string, callback: WatchCallback) {
        watch(() => bus.value.get(event), callback)
    }

    return {
        emit,
        on,
        bus,
    }
}
