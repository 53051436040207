<template>
    <ChatLayout>
        <Head :title="lastMessage?.original_question" />
        <ClipConversation :scroll-to="scrollTo" />
    </ChatLayout>
</template>

<script setup lang="ts">
import ClipConversation from '@/Components/ClipConversation.vue'
import ChatLayout from '@/Layouts/ChatLayout.vue'
import { defineConversationStore, useConversationStore } from '@/Stores/conversation'
import { definePodcasterStore } from '@/Stores/podcaster'
import { defineUserStore } from '@/Stores/user'
import type { ClipFinderConversationResource } from '@/types'
import { Head } from '@inertiajs/vue3'
import { storeToRefs } from 'pinia'
import { useYoutubeScripts } from '@/Composables/useYoutubeScripts'

const { conversation, shared } = defineProps<{
    conversation: ClipFinderConversationResource
    shared?: boolean
    scrollTo?: string
    social_image?: string
}>()

useYoutubeScripts()

defineUserStore()
defineConversationStore(conversation, shared)
definePodcasterStore(conversation.participants)

const store = useConversationStore()
const { lastMessage } = storeToRefs(store)
</script>
