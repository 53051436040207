<template>
    <div
        ref="sidebar"
        class="absolute z-50 h-full flex-shrink-0 bg-gray-800 transition-all duration-500 md:relative md:z-auto md:duration-300"
        :class="{ 'w-full md:w-[340px]': open, 'invisible translate-x-[-100%] md:w-0 md:translate-x-0': !open }"
    >
        <div v-if="open" class="absolute right-0 top-0 z-50 mr-3 pt-4 opacity-100 md:hidden">
            <button
                @click="toggle"
                type="button"
                class="relative rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                tabindex="0"
            >
                <span class="sr-only">Close sidebar</span>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6">
                    <path
                        d="M6.34315 6.34338L17.6569 17.6571M17.6569 6.34338L6.34315 17.6571"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
            </button>
        </div>

        <div class="flex h-full min-h-0 flex-col overflow-x-hidden">
            <div class="flex h-full min-h-0 flex-col transition-opacity" :class="{ 'md:opacity-50': hover, 'opacity-100': !hover }">
                <nav class="flex h-full w-full flex-col px-3 pb-4 pt-1" aria-label="Chat history">
                    <div class="-mr-2 flex-1 flex-col overflow-y-auto pr-2 transition-opacity duration-500 scrollbar-hide">
                        <div class="sticky left-0 right-0 top-0 z-20 bg-gray-800 pt-2">
                            <div class="pb-0.5 last:pb-0" tabindex="0" data-projection-id="7">
                                <Link
                                    @mouseover="hoveringLogo = true"
                                    @mouseleave="hoveringLogo = false"
                                    class="flex h-10 cursor-pointer items-center gap-2 rounded-lg px-2 font-medium"
                                    :href="route('clipfinder.home')"
                                    :only="['user']"
                                >
                                    <PodcastclipfinderIcon :animate="hoveringLogo" class="-mx-2 h-8 shrink-0 fill-current text-white" />
                                    <span class="ml-1 text-xl font-black tracking-wide text-white">Clipfinder</span>
                                </Link>
                            </div>
                        </div>

                        <div class="mt-6">
                            <!-- Start new chat -->
                        </div>

                        <div class="flex flex-col gap-2 pb-2 text-sm text-gray-800 dark:text-gray-100">
                            <div class="relative">
                                <div
                                    :class="{ 'pointer-events-none opacity-50': !loggedIn }"
                                    v-for="group in groupedConversations"
                                    class="relative mt-5 text-white"
                                    :key="group.name"
                                >
                                    <div v-if="group.conversations.length > 0">
                                        <div>
                                            <h3
                                                class="h-9 overflow-hidden text-ellipsis break-all px-2 px-3 pb-2 pt-3 text-xs font-medium text-gray-500"
                                            >
                                                {{ group.name }}
                                            </h3>
                                        </div>
                                        <ol>
                                            <ConversationHistory
                                                v-for="conversation in group.conversations"
                                                :conversation="conversation"
                                                :active="conversation.id === conversationId"
                                                :key="conversation.id"
                                            />
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="border-token-border-light flex flex-col gap-1 border-t border-t-0 border-black/20 pt-2 empty:hidden dark:border-white/20"
                    >
                        <div class="flex w-full items-center">
                            <div class="grow">
                                <Menu as="div" v-if="loggedIn" class="group relative inline-block w-full text-left">
                                    <MenuButton
                                        class="flex w-full items-center gap-2 gap-3 rounded-lg p-1 p-3 text-sm hover:bg-gray-100 hover:bg-gray-800"
                                        type="button"
                                    >
                                        <div class="flex-shrink-0">
                                            <img
                                                class="h-8 w-8 rounded-full bg-white ring ring-white"
                                                :src="user?.avatar_url || '/img/avatar.png'"
                                                :alt="user?.name || 'Guest'"
                                            />
                                        </div>
                                        <div
                                            class="relative -top-px grow -space-y-px overflow-hidden text-ellipsis whitespace-nowrap text-left text-gray-100"
                                        >
                                            <div class="font-semibold">
                                                <span>{{ user?.name }}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <ChevronDownIcon class="h-5 w-5 text-white" aria-hidden="true" />
                                        </div>

                                        <transition
                                            enter-active-class="transition duration-100 ease-out"
                                            enter-from-class="transform scale-95 opacity-0"
                                            enter-to-class="transform scale-100 opacity-100"
                                            leave-active-class="transition duration-75 ease-in"
                                            leave-from-class="transform scale-100 opacity-100"
                                            leave-to-class="transform scale-95 opacity-0"
                                        >
                                            <MenuItems
                                                class="absolute bottom-16 left-0 mt-2 w-full origin-bottom-left divide-y divide-gray-100 rounded-md bg-white/80 shadow-lg ring-1 ring-black/5 backdrop-blur focus:outline-none"
                                            >
                                                <div class="px-1 py-1">
                                                    <!--                                                    <MenuItem>-->
                                                    <!--                                                        <button-->
                                                    <!--                                                            class="group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium text-gray-800"-->
                                                    <!--                                                        >-->
                                                    <!--                                                            <UserIcon class="mr-2 h-5 w-5" aria-hidden="true" />-->
                                                    <!--                                                            Profile-->
                                                    <!--                                                        </button>-->
                                                    <!--                                                    </MenuItem>-->
                                                    <MenuItem>
                                                        <Link
                                                            href="/logout"
                                                            method="delete"
                                                            as="button"
                                                            type="button"
                                                            class="group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium text-gray-800"
                                                        >
                                                            <ArrowRightOnRectangleIcon class="mr-2 h-5 w-5" aria-hidden="true" />
                                                            Logout
                                                        </Link>
                                                    </MenuItem>
                                                </div>
                                            </MenuItems>
                                        </transition>
                                    </MenuButton>
                                </Menu>
                                <div v-else class="borders rounded-lg bg-white/50 px-5 py-4 text-center shadow backdrop-blur">
                                    <div>
                                        <PodcastclipfinderIcon class="mx-auto h-8" />
                                    </div>
                                    <div class="mt-3 text-base font-semibold">Create an account</div>
                                    <span> To see your history and get access to more podcasters </span>

                                    <div class="mt-5">
                                        <GoogleLoginButton />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { Link } from '@inertiajs/vue3'
import { useMenuStore } from '@/Stores/menu'
import { useConversationStore } from '@/Stores/conversation'
import { storeToRefs } from 'pinia'
import ConversationHistory from '@/Components/ConversationHistory.vue'
import PodcastclipfinderIcon from '@/Icons/PodcastclipfinderIcon.vue'
import { computed, ref } from 'vue'
import type { ClipFinderConversationResource } from '@/types'
import { useUserStore } from '@/Stores/user'
import GoogleLoginButton from '@/Components/GoogleLoginButton.vue'
import { ChevronDownIcon, ArrowRightOnRectangleIcon, UserIcon } from '@heroicons/vue/24/outline'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { onClickOutside } from '@vueuse/core'
import ChatBubbles from '@/Components/ChatBubbles.vue'

const userStore = useUserStore()
const menuStore = useMenuStore()
const conversationStore = useConversationStore()

const { toggle, close } = menuStore
const { open, hover, mobile } = storeToRefs(menuStore)
const { conversationId, conversations } = storeToRefs(conversationStore)

const { user, loggedIn } = storeToRefs(userStore)

const hoveringLogo = ref(false)
const sidebar = ref(null)

if (mobile.value) {
    onClickOutside(sidebar, () => {
        if (!open.value) {
            return
        }

        close()
    })
}

const groupedConversations = computed(() => {
    const now = new Date()
    const today = now.setHours(0, 0, 0, 0)
    const yesterday = new Date(today).setDate(now.getDate() - 1)
    const last7Days = new Date(today).setDate(now.getDate() - 7)

    const grouped: {
        [key: string]: {
            name: string
            conversations: ClipFinderConversationResource[]
        }
    } = {
        today: {
            name: 'Today',
            conversations: [],
        },
        yesterday: {
            name: 'Yesterday',
            conversations: [],
        },
        previous_7_days: {
            name: 'Previous 7 Days',
            conversations: [],
        },
        older: {
            name: 'Older',
            conversations: [],
        },
    }

    conversations.value.forEach((conversation: ClipFinderConversationResource) => {
        const updatedDate = new Date(conversation.updated_at).setHours(0, 0, 0, 0)

        if (updatedDate === today) {
            grouped.today.conversations.push(conversation)
        } else if (updatedDate === yesterday) {
            grouped.yesterday.conversations.push(conversation)
        } else if (updatedDate >= last7Days) {
            grouped.previous_7_days.conversations.push(conversation)
        } else {
            grouped.older.conversations.push(conversation)
        }
    })

    return grouped
})
</script>
