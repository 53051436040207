<template>
    <li
        class="inline-flex flex-nowrap items-center rounded-full border border-gray-200 bg-white px-1 py-0.5 hover:cursor-pointer hover:border-gray-400 hover:bg-gray-50"
        @click="toggle"
        :class="{
            'opacity-40': !selected,
        }"
    >
        <picture class="mr-1.5 inline-block size-6 rounded-full">
            <source class="size-6 rounded-full" :srcset="podcaster.webp_picture_url" type="image/webp" height="24" width="24" />
            <img class="size-6 rounded-full" :src="podcaster.picture_url" :alt="podcaster.name" height="24" width="24" />
        </picture>

        <div class="whitespace-nowrap text-sm font-medium text-gray-800">
            {{ podcaster.name }}
        </div>
        <button
            v-if="!selected"
            class="ms-2.5 inline-flex size-5 cursor-pointer items-center justify-center rounded-full bg-gray-200 text-gray-800 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
        >
            <PlusIcon class="size-3 flex-shrink-0" />
        </button>
        <button
            v-else-if="canDelete"
            class="ms-2.5 inline-flex size-5 cursor-pointer items-center justify-center rounded-full bg-red-100 text-red-800 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-red-400"
        >
            <XMarkIcon class="size-3 flex-shrink-0" />
        </button>
        <button v-else class="size-2"></button>
    </li>
</template>
<script setup lang="ts">
import type { PodcasterResource } from '@/types'
import { XMarkIcon, PlusIcon } from '@heroicons/vue/24/outline'

const props = defineProps<{
    podcaster: PodcasterResource
    selected: boolean
    canDelete: boolean
}>()

const $emit = defineEmits(['add', 'remove'])

const toggle = () => {
    if (!props.selected) {
        $emit('add', props.podcaster)
        return
    }

    if (!props.canDelete) {
        return
    }

    $emit('remove', props.podcaster)
}
</script>
