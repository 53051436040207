<template>
    <div class="relative">
        <span class="animate-pulse break-all blur transition-all duration-300">{{ text }}</span>
    </div>
</template>
<script setup lang="ts">
import { randomString } from '@/Utils/String'
import type { ComputedRef } from 'vue'
import { computed } from 'vue'

const props = defineProps<{
    length: number
}>()

const text: ComputedRef<string> = computed((): string => {
    return randomString(props.length)
})
</script>
