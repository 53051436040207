<template>
    <div class="mx-4 leading-6 text-black md:mx-0">
        <form @submit.prevent="submit" class="relative w-full max-w-full text-black md:mx-auto lg:max-w-2xl xl:max-w-3xl">
            <div ref="$conversationStartersEl" v-if="conversationStarters.length > 0" class="mb-2">
                <div class="grid w-full grid-flow-row grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2">
                    <div
                        v-for="conversationStartersChunk in conversationStartersChunked"
                        class="flex flex-col gap-2 text-black last:hidden last:md:flex"
                    >
                        <ConversationStarter
                            v-for="conversationStarter in conversationStartersChunk"
                            :conversation-starter="conversationStarter"
                            :key="conversationStarter.id"
                            @click="selectStarter(conversationStarter)"
                        />
                    </div>
                </div>
            </div>

            <SearchInput
                v-model="form.query"
                @submit="submit"
                :disabled="isWaiting"
                placeholder="Ask a question..."
                :error="errors.hasOwnProperty('question')"
            />
        </form>
        <div class="relative py-3 text-center text-xs leading-4 text-gray-600 md:px-16 md:py-4">
            <span class="text-gray-600">
                Clipfinder.com knows all the podcasts until
                <time class="italic" datetime="{{ trainingDate }}">{{ trainingDate.toLocaleDateString() }}</time>
            </span>
        </div>
    </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, reactive, ref } from 'vue'
import { usePodcasterStore } from '@/Stores/podcaster'
import type { ConversationStarterResource } from '@/types'
import { ScrollState, useConversationStore } from '@/Stores/conversation'
import ConversationStarter from '@/Components/ConversationStarter.vue'
import { useMotion } from '@vueuse/motion'
import SearchInput from '@/Components/SearchInput.vue'

const props = defineProps<{
    conversationStarters: ConversationStarterResource[]
}>()

const $conversationStartersEl = ref<HTMLElement>()

const form = reactive({
    query: '',
})

const errors = ref<{ string: string[] }[]>([])

const podcasterStore = usePodcasterStore()
const conversationStore = useConversationStore()
const { sendMessage } = conversationStore
const { scrollState } = storeToRefs(conversationStore)
const { trainingDate } = storeToRefs(podcasterStore)

const emit = defineEmits(['submit'])

const selectStarter = async (conversationStarter: ConversationStarterResource) => {
    form.query = conversationStarter.title + ' ' + conversationStarter.description

    await submit()
}

const isWaiting = computed(() => scrollState.value === ScrollState.Requesting)

const submit = async () => {
    if (isWaiting.value) {
        return
    }

    try {
        const question = form.query
        form.query = ''

        await sendMessage(question)

        emit('submit', question)
    } catch (e: any) {
        errors.value = e.errors || []
    }
}

const conversationStartersChunked = computed(() => {
    if (!props.conversationStarters) {
        return []
    }

    const chunked = []
    for (let i = 0; i < props.conversationStarters.length; i += 2) {
        chunked.push(props.conversationStarters.slice(i, i + 2))
    }

    return chunked
})

useMotion($conversationStartersEl, {
    initial: { opacity: 0, y: 20 },
    enter: { opacity: 1, y: 0, transition: { delay: 300, duration: 400 } },
    leave: { opacity: 0, y: 20, transition: { delay: 0, duration: 300 } },
})
</script>
