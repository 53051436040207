<template>
    <button @click="toggle" class="group flex cursor-pointer items-center gap-1 rounded-xl px-3 font-medium hover:bg-gray-50 md:py-2 md:text-lg">
        <div v-if="selectedPodcasters.length > 0" class="flex -space-x-2 overflow-hidden">
            <picture
                v-for="participant in selectedPodcasters.slice(0, 6)"
                :key="participant.id"
                class="inline-block size-6 rounded-full bg-gray-600 ring-2 ring-white md:size-8"
            >
                <source class="size-6 rounded-full md:size-8" :srcset="participant.webp_picture_url" type="image/webp" height="24" width="24" />
                <img class="size-6 rounded-full md:size-8" :src="participant.picture_url" :alt="participant.name" height="24" width="24" />
            </picture>

            <div
                v-if="invisiblePodcastersCount"
                class="z-50 inline-flex size-6 items-center justify-center rounded-full bg-primary text-sm font-semibold text-white ring-2 ring-white md:size-8"
            >
                +{{ invisiblePodcastersCount }}
            </div>
        </div>
        <ChevronDownIcon class="size-4 md:size-5" aria-hidden="true" />
    </button>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { usePodcasterStore } from '@/Stores/podcaster'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { computed } from 'vue'

const podcasterStore = usePodcasterStore()

const { toggle } = podcasterStore
const { selectedPodcasters } = storeToRefs(podcasterStore)

const invisiblePodcastersCount = computed(() => Math.max(0, selectedPodcasters.value.length - 6))
</script>
