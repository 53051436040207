<template>
    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M375.466667 429.866667 375.466667 170.666667l34.133333 0c5.1712 0 9.838933-1.518933 11.8272-6.2976s0.887467-9.4976-2.773333-13.1584l-51.2-50.798933c-5.000533-5.000533-13.098667-4.795733-18.0992 0.196267l-51.2 51.3024c-3.6608 3.6608-4.7616 7.620267-2.773333 12.407467S302.0288 170.666667 307.2 170.666667l34.133333 0 0 259.2c0 8.533333 8.533333 17.066667 17.066667 17.066667S375.466667 438.4 375.466667 429.866667z"
        />
        <path
            d="M324.266667 469.333333l-34.133333 0L290.133333 216.533333c0-8.533333-8.533333-17.066667-17.066667-17.066667s-17.066667 8.533333-17.066667 17.066667L256 469.333333l-34.133333 0c-5.1712 0-9.838933 4.718933-11.8272 9.4976s-0.887467 11.0848 2.773333 14.7456l51.2 51.601067c5.000533 5.000533 13.098667 5.1968 18.0992 0.196267l51.2-51.106133c3.6608-3.6608 4.7616-10.709333 2.773333-15.496533S329.437867 469.333333 324.266667 469.333333z"
        />
        <path
            d="M755.0464 510.933333c-3.473067 0-12.288 1.194667-21.3504 4.010667-2.312533-30.830933-28.151467-55.210667-59.562667-55.210667-14.139733 0-27.153067 4.9408-37.393067 13.184-7.569067-23.108267-27.579733-38.784-52.360533-38.784-16.4096 0-31.010133 6.749867-41.4208 17.774933l-5.666133-158.574933c0-36.488533-25.693867-64-59.767467-64s-59.767467 27.511467-59.767467 64.273067l5.853867 273.28c-6.894933 3.746133-17.3568 10.5984-30.7456 22.7584-46.327467 42.103467-61.4912 143.940267-9.173333 222.762667 58.24 87.739733 126.481067 125.192533 228.1984 125.192533 107.767467 0 143.8208-54.084267 171.52-109.482667 26.641067-53.282133 26.948267-257.3312 26.948267-265.984C810.368 534.852267 784.520533 510.933333 755.0464 510.933333zM760.516267 816.6656c-26.077867 52.155733-55.253333 95.3344-148.616533 95.3344-93.320533 0-153.250133-32.955733-206.865067-113.749333-31.240533-47.069867-44.8-144.349867 5.060267-189.6704 5.384533-4.8896 10.1376-8.721067 14.165333-11.6992l1.911467 89.250133c0.1536 7.0656 5.981867 12.7488 13.073067 12.526933 7.0656-0.1536 12.672-5.998933 12.526933-13.073067l-8.3968-392.251733c0-22.6048 14.045867-38.4 34.167467-38.4s34.167467 15.7952 34.176 38.8608l8.533333 238.933333c0.2304 6.570667 5.4016 11.886933 11.946667 12.3136 6.408533 0.4352 12.3648-4.164267 13.4656-10.641067l6.4768-38.4c0.119467-0.708267 0.1792-1.416533 0.1792-2.133333 0-19.456 13.789867-34.133333 32.0768-34.133333 20.625067 0 30.020267 17.6896 30.020267 34.133333l0 25.6c0 7.074133 5.7344 12.8 12.8 12.8s12.8-5.7344 12.8-12.8c0-18.824533 15.3088-34.133333 34.133333-34.133333s34.133333 15.3088 34.133333 34.133333l0 55.466667c0 7.0656 5.7344 12.8 12.8 12.8s12.8-5.7344 12.8-12.8l0-32.597333c5.12-2.491733 15.1552-5.802667 21.179733-5.802667 15.274667 0 29.713067 12.433067 29.713067 25.6C784.768 619.4432 780.398933 776.900267 760.516267 816.6656z"
        />
    </svg>
</template>
