export function useYoutubeScripts() {
    const scripts: string[] = ['https://www.youtube.com/player_api']
    scripts.forEach((script) => {
        let tag = document.head.querySelector(`[src="${script}"`)
        if (!tag) {
            tag = document.createElement('script')
            tag.setAttribute('src', script)
            tag.setAttribute('type', 'text/javascript')
            document.head.appendChild(tag)
        }
    })
}
