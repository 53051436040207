<template>
    <div class="mb-0 mt-3 flex items-start leading-5 text-slate-500 md:mt-5" :class="{ 'justify-end': align === 'right' }">
        <div v-if="avatar" class="relative inline-flex size-7 flex-shrink-0 text-slate-500 md:size-10" :class="{ 'order-1': align === 'right' }">
            <img
                class="block h-full w-full max-w-full rounded-full object-cover object-center align-middle ring-1 ring-gray-300"
                :src="avatar"
                alt="avatar"
            />
        </div>

        <div
            class="ml-2.5 mr-0 flex flex-col items-start sm:mr-0 md:ml-5"
            :class="{
                'items-end': align === 'right',
                'items-start': align === 'left',
            }"
        >
            <div
                :class="{
                    'mr-1 max-w-xl sm:mr-4': align === 'right',
                    'sm:mr-4': align === 'left',
                }"
            >
                <div>
                    <div v-if="thinking">
                        <div class="mt-3 animate-pulse font-normal text-slate-600">Thinking...</div>
                    </div>

                    <slot v-else />

                    <div class="ml-0.5 mt-3 text-xs md:flex md:items-start md:justify-between">
                        <div class="mb-0 mr-0 text-right text-xs leading-4 text-slate-400" v-if="!thinking && align === 'right'">
                            {{ time }}
                        </div>
                        <slot name="metadata" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import moment from 'moment/moment'
import type { ComputedRef } from 'vue'
import { computed } from 'vue'

const { date } = defineProps<{
    align: string
    avatar?: string
    date: string
    thinking?: Boolean
    failed?: Boolean
}>()

const time: ComputedRef<string | null> = computed((): string | null => {
    if (!date) {
        return null
    }

    return moment(date).format('h:mm')
})
</script>
