<template>
    <SlideOver title="Find clips from:" :open="open" @close="onClose" @submit="save">
        <div class="mt-5 divide-y divide-gray-200 px-4 sm:px-6">
            <div>
                <div class="flex items-end justify-between space-x-4">
                    <div class="flex flex-1 rounded-md shadow-sm">
                        <div class="relative flex flex-grow items-stretch focus-within:z-10">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" />
                            </div>
                            <input
                                v-model="query"
                                type="search"
                                name="query"
                                id="query"
                                class="block w-full rounded-none rounded-l-lg border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                                placeholder="Search..."
                            />
                            <button
                                @click="toggle"
                                type="button"
                                class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                <CursorArrowRaysIcon class="-ml-0.5 size-5 text-gray-500" />
                                <span v-if="podcasters.length === form.podcasters.length">None</span>
                                <span v-else>All</span>
                            </button>
                        </div>
                    </div>
                </div>
                <ul role="list" class="mt-4 flex flex-row flex-wrap gap-1">
                    <PodcasterSelector
                        v-for="podcaster in filteredPodcasters"
                        :key="podcaster.id"
                        :podcaster="podcaster"
                        :selected="form.podcasters.filter((pid: string) => pid === podcaster.id).length === 1"
                        :can-delete="form.podcasters.length > 1"
                        @add="add"
                        @remove="remove"
                    />
                </ul>
            </div>
        </div>

        <template #buttons>
            <button
                @click="startNewChat"
                role="button"
                class="ml-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-primary-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
                Start a new chat
            </button>
            &nbsp; &nbsp;
            <button
                type="button"
                class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                @click="open = false"
            >
                Cancel
            </button>
            <button
                type="submit"
                class="ml-3 inline-flex justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
                Save
            </button>
        </template>
        <WarningModal v-if="showWarningModal" @close="closeWarningModal" />
        <ConfirmationModal v-if="showModal" @close="closeModal" @confirm="startNewChat" />
    </SlideOver>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { router } from '@inertiajs/vue3'
import { usePodcasterStore } from '@/Stores/podcaster'
import { MagnifyingGlassIcon, CursorArrowRaysIcon } from '@heroicons/vue/24/outline'
import { computed, inject, reactive, ref, watch } from 'vue'
import type { PodcasterResource } from '@/types'
import { useConversationStore } from '@/Stores/conversation'
import PodcasterSelector from '@/Layouts/Partials/PodcasterSelector.vue'
import SlideOver from '@/Components/SlideOver.vue'
import WarningModal from '@/Components/WarningModal.vue';
import ConfirmationModal from '@/Components/ConfirmationModal.vue';

const route = inject('route')

const podcasterStore = usePodcasterStore()
const conversationStore = useConversationStore()

const { selectPodcasters } = podcasterStore
const { updateParticipants } = conversationStore

const { conversationId } = storeToRefs(conversationStore)
const { open, podcasters, selectedPodcastersIds } = storeToRefs(podcasterStore)

interface ConversationForm {
    podcasters: string[]
    year?: number
}

const query = ref('')
const form: ConversationForm = reactive({
    podcasters: JSON.parse(JSON.stringify(selectedPodcastersIds.value)),
})

const showWarningModal = ref(false)
const showModal = ref(false);

// Revert when closed
watch(
    () => open.value,
    (newValue: Boolean, oldValue: Boolean) => {
        if (newValue === true && oldValue === false) {
            form.podcasters = JSON.parse(JSON.stringify(selectedPodcastersIds.value))
            showWarningModal.value = false
            showModal.value = false
        }
    }
)

const toggle = () => {
    if (form.podcasters.length === podcasters.value.length) {
        form.podcasters = []
        return
    }

    form.podcasters = podcasters.value.map((p) => p.id)
}

const add = (podcaster: PodcasterResource) => {
    form.podcasters.push(podcaster.id)
}

const remove = (podcaster: PodcasterResource) => {
    const index = form.podcasters.findIndex((pid: string) => pid === podcaster.id)

    if (index >= 0) {
        form.podcasters.splice(index, 1)
    }
}

const save = async () => {
    if (form.podcasters.length < 1) {
        showWarningModal.value = true;
        return
    }

    if (conversationId.value) {
        await updateParticipants(form.podcasters)
    }

    selectPodcasters(form.podcasters)

    open.value = false
}

const startNewChat = async () => {
    if (form.podcasters.length < 1) {
        showWarningModal.value = true;
        return
    }

    selectPodcasters(form.podcasters)
    open.value = false

    router.visit(route('clipfinder.conversations.create'))
}

const onClose = () => {
  if (form.podcasters.length > 0) {
    showModal.value = true;
  } else {
    open.value = false;
  }
};

const closeModal = () => {
  showModal.value = false;
  open.value = false;
};

const closeWarningModal = () => {
  showWarningModal.value = false
}

const filteredPodcasters = computed(() => {
    const result =
        query.value === ''
            ? podcasters.value
            : podcasters.value.filter((podcaster: PodcasterResource) => {
                  return podcaster.name.toLowerCase().includes(query.value.toLowerCase())
              })
    // Sorting the result array by name in ascending order
    return result.sort((a: PodcasterResource, b: PodcasterResource) => {
        return a.name.localeCompare(b.name)
    })
})
</script>
