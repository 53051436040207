<template>
    <div class="mx-2 flex-1 overflow-hidden md:mx-0">
        <ChatHeader />
        <div class="relative h-[85vh]">
            <div class="h-full w-full overflow-y-auto" id="messages" ref="scroller">
                <div class="flex h-full flex-col pb-9 text-sm dark:bg-transparent">
                    <div ref="$podcasterEL" v-if="messages.length === 0" class="flex h-full flex-col items-center justify-center">
                        <div class="mb-6">
                            <div class="relative flex h-full items-center justify-center rounded-full text-black">
                                <div class="flex -space-x-8 overflow-hidden">
                                    <a
                                        v-for="podcaster in selectedPodcasters.slice(0, 6)"
                                        :key="podcaster.id"
                                        :href="route('clipfinder.podcasters.show', podcaster.slug)"
                                    >
                                        <picture class="inline-block size-20 rounded-full bg-gray-400 ring-2 ring-gray-100 md:size-32">
                                            <source
                                                class="size-20 rounded-full md:size-32"
                                                :srcset="podcaster.webp_picture_url"
                                                type="image/webp"
                                                height="160"
                                                width="160"
                                            />
                                            <img
                                                class="size-20 rounded-full md:size-32"
                                                :src="podcaster.picture_url"
                                                :alt="podcaster.name"
                                                height="160"
                                                width="160"
                                                loading="lazy"
                                            />
                                        </picture>
                                    </a>
                                    <div
                                        v-if="invisiblePodcastersCount"
                                        class="z-0 inline-flex size-20 items-center justify-center rounded-full bg-primary ring-2 ring-white md:size-32"
                                    >
                                        <span class="pl-0 text-base font-bold text-white md:pl-0 md:text-3xl">+{{ invisiblePodcastersCount }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-5 text-center text-lg font-medium md:text-2xl">
                            <div>Start listening to</div>
                            <a
                                v-for="podcaster in selectedPodcasters.slice(0, 6)"
                                :href="route('clipfinder.podcasters.show', podcaster.slug)"
                                class="mx-0.5 inline-block rounded bg-primary px-1.5 text-base text-white md:text-2xl"
                                >{{ podcaster.name }}</a
                            >

                            <span
                                v-if="invisiblePodcastersCount"
                                class="mx-0.5 inline-block rounded bg-primary px-1.5 text-base text-white md:text-2xl"
                                >+{{ invisiblePodcastersCount }}</span
                            >
                            <div>where your interests begin.</div>
                        </div>
                    </div>

                    <div class="relative mx-auto w-full px-3 pb-4 md:max-w-2xl md:px-5 lg:px-1 xl:max-w-3xl xl:px-5">
                        <div v-for="(message, i) in messages" :key="message.id">
                            <ChatMessage align="left" :avatar="user?.avatar_url || '/img/avatar.png'" :date="message.date">
                                <ChatBubble align="left" :primary="true">
                                    <div class="text-sm font-bold">You</div>
                                    <div class="-mt-1">
                                        {{ message.original_question }}
                                    </div>
                                </ChatBubble>
                            </ChatMessage>

                            <PodcasterMessage
                                :message="message"
                                @topic-selected="scrollToBottom(scroller, { smooth: true })"
                                :is-last-message="i === messages.length - 1"
                            />
                        </div>

                        <div ref="endOfScroller"></div>
                        <FailedMessage v-if="scrollState === ScrollState.Failed">
                            We couldn't process your query due to an internal error. Please try again later.
                        </FailedMessage>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ChatFooter
        v-if="!shared"
        :conversation-starters="messages.length === 0 ? conversationStarters : []"
        @submit="scrollToBottom(scroller, { smooth: true })"
    />
    <div v-else>
        <div class="mx-4 mb-4 leading-6 text-black md:mx-0">
            <div class="relative flex w-full max-w-full justify-center text-black md:mx-auto lg:max-w-2xl xl:max-w-3xl">
                <!--                <Link-->
                <!--                    :href="route('clipfinder.podcasters.show', podcaster.slug)"-->
                <!--                    class="inline-block rounded-lg border bg-white px-4 py-1.5 text-primary-600 shadow-md hover:bg-primary-50 hover:text-primary-800"-->
                <!--                >-->
                <!--                    Start your own conversation with {{ podcaster.name }}-->
                <!--                </Link>-->
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import FailedMessage from '@/Components/FailedMessage.vue'
import PodcasterMessage from '@/Components/PodcasterMessage.vue'
import { OverscrollDirection, useInfiniteScroll } from '@/Composables/useScrollEvents'
import type { OverscrollOptions } from '@/Composables/useScrollEvents'
import scrollToBottom from '@/Composables/useScrolltoBottom'
import { ScrollState, useConversationStore } from '@/Stores/conversation'
import { usePodcasterStore } from '@/Stores/podcaster'
import { storeToRefs } from 'pinia'
import { onMounted, computed, ref } from 'vue'
import ChatHeader from '@/Components/ChatHeader.vue'
import ChatFooter from '@/Components/ChatFooter.vue'
import { useMotion } from '@vueuse/motion'

import ChatBubble from '@/Components/ChatBubble.vue'
import ChatMessage from '@/Components/ChatMessage.vue'
import { useUserStore } from '@/Stores/user'

const props = defineProps<{
    scrollTo?: string
}>()

const userStore = useUserStore()
const podcasterStore = usePodcasterStore()
const conversationStore = useConversationStore()

const { user } = storeToRefs(userStore)
const { selectedPodcasters, conversationStarters } = storeToRefs(podcasterStore)

const { loadMoreClips } = conversationStore
const { messages, scrollState, shared } = storeToRefs(conversationStore)

const $podcasterEL = ref<HTMLElement>()
const scroller = ref<HTMLElement>()
const endOfScroller = ref<HTMLElement>()

onMounted(() => {
    if (shared && props.scrollTo) {
        const $scrollToEl = document.getElementById('clip_' + props.scrollTo)
        $scrollToEl?.scrollIntoView()
    }

    useInfiniteScroll(scroller.value, endOfScroller.value, loadMoreClips, {
        direction: OverscrollDirection.bottom,
        rootMargin: '750px',
    } as OverscrollOptions)
})

useMotion($podcasterEL, {
    initial: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0, transition: { delay: 0, duration: 500 } },
    leave: { opacity: 0, y: -10, transition: { delay: 0, duration: 200 } },
})

const invisiblePodcastersCount = computed(() => Math.max(0, selectedPodcasters.value.length - 6))
</script>
