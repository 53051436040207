<template>
    <div>
        <div
            class="left-0 top-1/2 z-40 hidden -translate-y-1/2 rotate-0 transition-transform md:fixed md:block"
            :class="{ 'translate-x-[340px] rotate-0': open, 'translate-x-0 rotate-180': !open }"
        >
            <button class="group" @click="toggle" @mouseover="onHover(true)" @mouseleave="onHover(false)">
                <span>
                    <div class="flex h-[72px] w-8 items-center justify-center opacity-25 group-hover:opacity-100">
                        <div class="flex h-6 w-6 flex-col items-center">
                            <div
                                class="h-5 w-1 translate-y-[0.15rem] rotate-0 rounded-full bg-black transition-transform group-hover:rotate-[15deg]"
                            ></div>
                            <div
                                class="-mt-1 h-5 w-1 translate-y-[0.15rem] rotate-0 rounded-full bg-black transition-transform group-hover:rotate-[-15deg]"
                            ></div>
                        </div>
                    </div>
                    <span class="sr-only">Close sidebar</span>
                </span>
            </button>
        </div>

        <!--        <div class="absolute right-16 top-0 z-50 pt-3.5 opacity-100 md:hidden" v-if="open">-->
        <!--            <button-->
        <!--                @click="toggle"-->
        <!--                type="button"-->
        <!--                class="ml-1 flex h-10 w-10 items-center justify-center text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"-->
        <!--                tabindex="0"-->
        <!--            >-->
        <!--                <span class="sr-only">Close sidebar</span-->
        <!--                ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md">-->
        <!--                    <path-->
        <!--                        d="M6.34315 6.34338L17.6569 17.6571M17.6569 6.34338L6.34315 17.6571"-->
        <!--                        stroke="currentColor"-->
        <!--                        stroke-width="2"-->
        <!--                        stroke-linecap="round"-->
        <!--                        stroke-linejoin="round"-->
        <!--                    ></path>-->
        <!--                </svg>-->
        <!--            </button>-->
        <!--        </div>-->
    </div>
</template>
<script setup lang="ts">
import { useMenuStore } from '@/Stores/menu'
import { storeToRefs } from 'pinia'

const store = useMenuStore()
const { toggle, onHover } = store
const { open } = storeToRefs(store)
</script>
